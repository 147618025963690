import styled from "styled-components";
import {MapContainer, TileLayer, Marker} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import {Icon} from 'leaflet'


const StyledMapContainer = styled(MapContainer)`
    height: 500px
`;

function LocationMap({latitude, longitude}) {
    
    const mapOptions = {
        center: [latitude, longitude],
        zoom: 13,
        maxZoom: 18,
        minZoom: 5,
    };
    return(
    <StyledMapContainer {...mapOptions}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      <Marker position={[latitude, longitude]}  icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}>
      </Marker>
    </StyledMapContainer>
    );
}

export default LocationMap;
