import {Outlet} from "react-router-dom";
import Navbar from "./Navbar";
import styled from "styled-components";
import GlobalStyle from "../GlobalStyle";

const StyledLayout = styled.div`
    width: 1080px;
    margin: auto;
`;

const Spacer = styled.div`
    height: 5vh;
`;

const Main = styled.main`
    margin-left: 10px;
    margin-right: 10px;
`

function Layout() {
    const routes = [
        {to:"/", name:"Verabschiedung"},
        {to:"/memorial", name:"Trauerfeier"},
        {to:"/photos", name:"Fotos"},
        {to:"/text", name:"Texte"},
    ];
    return (
        <>
            <GlobalStyle />
            <StyledLayout>
                <title>In Gedenken</title>
                <Navbar routes={routes}/>
                <Main>
                    <Spacer />
                    <Outlet />
                </Main>
            </StyledLayout>
        </>
    );
}

export default Layout;
