import {CenterDiv} from "../components/Styling";

function Photos() {
    return(
        <CenterDiv>
            <h1>Fotos</h1>
            <p>
                Wir haben eine Bildersammlung eingerichtet. Ihr könnt dort Bilder hochladen und mit der Familie teilen.<b/>
            </p>
            <a href="https://nextcloud.martin-nettesheim.de/s/dRKNx8w6B7c5nD9"> Link zur Foto-Cloud</a>
        </CenterDiv>
    );
}

export default Photos;
