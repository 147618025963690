import styled from "styled-components";

export const Par = styled.p`
    font-size: 1.5rem;
    line-height: 1.5
`;

export const CenterPar = styled(Par)`
    text-align: center;
    font-size: 1.5rem;
`;

export const CenterDiv = styled.div`
    text-align: center;
`

export const SmallSpan = styled.span`
    font-size: 1rem;
`;
