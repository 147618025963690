import {CenterDiv, SmallSpan} from "../components/Styling";

function Texts() {
    return (
        <CenterDiv>
            <h1>Einst bei einer Strandwanderung</h1>
            <par>
                Johannes und ich waren auf einer Strandwanderung in Schouwen-Duiveland ein
                wenig zurückgefallen. Papa war wie immer die Nase im Wind mit seinem
                unverkennbaren weiten Schritt ein wenig voraus. Maria auf der Schulter, Florian
                an der Hand. Andreas irgendwo und Mama dazwischen.
                <br/><br/>
                Plötzlich findet Johannes 10 Mark im Sand! Dann noch ein wenig Kleingeld. Dort
                liegt noch ein Schein. Das Sammlerfieber war geweckt und es kam ein Sümmchen
                zusammen, das unsere Fanatasie massiv anregte: was wir uns damit alles kaufen
                könnten...
                <br/><br/>
                In Renesse Strand angekommen schlägt Papa vor, uns alle auf ein Eis
                einzuladen. Alle sind begeistert - bis es ans zahlen geht. Martins Tasche hat
                ein Loch und die Tasche ist leer. Der Eisverkäufer nicht begeistert. Da wussen
                Johannes und ich sofort welche Quelle unseren Überraschubfsfund gespeist hatte.
                So bleibt am Ende alles in der Familie.
                <br/><br/>
            </par>
            <SmallSpan> Frei aus der Erinnerung von Stefan</SmallSpan>
        </CenterDiv>
    );
}

export default Texts;
