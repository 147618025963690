import { useEffect } from "react";
import {RouterProvider, createBrowserRouter} from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Memorial from "./pages/Memorial";
import Photos from "./pages/Photos";
import Texts from "./pages/Texts";

const router =  createBrowserRouter([
    { 
        path: "/",
        element: <Layout/>,
        children: [
            { index: true, element: <Home /> },
            { path: "/memorial", element: <Memorial /> },
            { path: "/photos", element: <Photos /> },
            { path: "/text", element: <Texts /> },
        ],
    },
    {
        path: '*',
        element: <h1>PAGE NOT FOUND</h1>,
    },
]);

function App() {
    useEffect(() => {
        document.title = "In Gedenken an Martin Nettesheim"
    }, []);
    return (
        <RouterProvider router={router} />
    );
};

export default App;
