import {styled} from 'styled-components'
import {NavLink} from 'react-router-dom'
import mn2 from "./../assets/mn2.png";

const NavContainer = styled.nav`
    background-color: #333;
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    font-size: 2rem;
`;

const NavLinks = styled.div`
    display: flex;
    gap: 2vw;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const StyledNavLink = styled(NavLink)`
    color: white;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
    &.active {
        color: lightblue
    }
`;

const Header = styled.img`
    height: 10vh;
`;


function Navbar({routes}) {
    return (
        <NavContainer>
            <NavLinks>
                <Header src={mn2} alt="Zeichnung" />
                <p>
                    * 23.06.1936<br/> 
                    {'\u271d'} 21.07.2024 
                </p>
            </NavLinks>
            <NavLinks>
                {routes.map(route => {return (<StyledNavLink to={route.to} key={route.name}>{route.name}</StyledNavLink>)})}
            </NavLinks>
        </NavContainer>
    );
};

export default Navbar;
