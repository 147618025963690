import {createGlobalStyle} from "styled-components";
import CharterRegular from "../node_modules/charter-webfont/fonts/charter_regular.woff2"
import CharterBold from "../node_modules/charter-webfont/fonts/charter_bold.woff2"
import CharterItalic from "../node_modules/charter-webfont/fonts/charter_italic.woff2"
import CharterBoldItalic from "../node_modules/charter-webfont/fonts/charter_bold_italic.woff2"

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Charter';
        src: url(${CharterRegular}) format('woff2');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Charter';
        src: url(${CharterBold}) format('woff2');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Charter';
        src: url(${CharterItalic}) format('woff2');
        font-weight: normal;
        font-style: italic;
        font-display: swap;
    }
    @font-face {
        font-family: 'Charter';
        src: url(${CharterBoldItalic}) format('woff2');
        font-weight: bold;
        font-style: italic;
        font-display: swap;
    }
    body {
        font-family: 'Charter';
        font-size: 1.5rem;
    }
`;

export default GlobalStyle;
