import mn1 from "./../assets/mn1.jpeg";
import {CenterPar, CenterDiv, SmallSpan} from "../components/Styling";

function Home() {
    return (
        <div>
            <CenterPar>
                Wir feiern das Leben von<br/>
            </CenterPar>
            <CenterDiv>
                <img src={mn1} alt="Der Wanderer" />
            </CenterDiv>
            <CenterPar>
                Dr. Martin Johannes Nettesheim<br/>
                * 23.06.1936<br/>
                {'\u271d'} 21.07.2024<br/>
                <br/><br/>

                Wir kommen still und sehen:<br/>
                Ein Mensch geht uns voraus<br/>
                wohin wir alle gehen<br/>
                Der Weg führt uns nach Haus<br/>
                Wir kommen still und hoffen<br/>
                der Weg wird nicht zu hart<br/>
                Die Tür hält einer offen<br/>
                zu seiner Gegenwart.<br/>
                <SmallSpan>G. Schwikart</SmallSpan><br/>
                <br/><br/>

                Wir trauern:<br/>
                Mercedes<br/>
                Stefan, Katinka, Andri, Xaver<br/>
                Moritz, Jascha, Gian, Lino, Laila, Beate<br/>
                Johannes, Andrea, David<br/>
                Andreas, Astrid, Alina, Antonia<br/>
                Florian, Samantha, Gabriel<br/>
                Maria, Rachid, Younes, Loubna, Amin<br/>
            </CenterPar>
        </div>
    );
}

export default Home;
