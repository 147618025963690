// import {useRef} from "react";
import styled from "styled-components";
import LocationMap from "../components/LocationMap";
import {Par} from "../components/Styling";

const Heading = styled.h2`
`;

function Memorial() {
    // const mapRef = useRef(null);
    // const latitude = 51.505;
    // const longitude = -0.09;
    const lat = 52.40781;
    const lon = 13.59099;

    return ( 
    <div>
        <Heading>
            Datum der Beerdigung
        </Heading>
        <Par>
            19. September 2024, 11 Uhr<br/>
        </Par>
        <Heading>
            Ort
        </Heading>
        <Par> 
            Waldfriedhof Grünau<br/>
            Rabindranath-Tagore-Straße 18-20<br/>
            12527 Berlin<br/>
        </Par>
        <LocationMap latitude={lat} longitude={lon} />
        <Par>
            Nach der Beerdigung laden wir zu einem Imbiss.<br/>
            Um Antwort wird gebeten.<br/>

            <a href="mailto:email@example.com" subject="Trauerfeier Martin Nettesheim">Hier klicken um eine Antwort zu senden.</a>

            <br/>
            <br/>
            Statt Blumen bitten wir um eine Spende für die v. Bodelschwinghsche Stiftungen Bethel<br/>
            Sparkasse Bielefeld<br/>
            DE48 4805 0161 0000 0040 77<br/>
            Stichwort: Younes
        </Par>
    </div>
    );
}

export default Memorial;
